import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import useFetchInterval from '../../hooks/useFetchInterval';
import TimescaleLine from '../../components/TimescaleCharts/TimescaleLine';
import { useErrors } from '../../hooks/useErrors';
import { getStartOfDay, getEndOfDay } from '../../utils/dateUtils';
import DataAccessStatuses from "../../components/DataAccessStatuses";
import useScreenZoom from "../../hooks/useScreenZoom";

export default function DailyMemberDashboard({ fields, date, memberId, onDataStatusChange, t, onDateChange }) {
  const { resetZoom: resetScreenZoom } = useScreenZoom();
  resetScreenZoom();
  const [powers, setPowers] = useState([]);
  const [dataAccessStatus, setDataAccessStatus] = useState(DataAccessStatuses.LOADING);

  const errors = useErrors();
  useEffect(() => {
    resetScreenZoom();
  }, []);

  useEffect(() => {
    onDataStatusChange(dataAccessStatus);
  }, [onDataStatusChange, dataAccessStatus]);


  useFetchInterval(
    '/api/members/' + memberId + '/powers/?' +
    new URLSearchParams({
      step: 'PT3M',
      from: getStartOfDay(date).format('YYYY-MM-DD[T]HH:mm:ssZ'),
      to: getEndOfDay(date).format('YYYY-MM-DD[T]HH:mm:ssZ')
    }),
    (result) => {
      let transformedResult = result.map(row => ({
        ...row,
        "available": (row.produced - row.selfConsumed) + (row.producedByCommunity - row.consumedFromCommunity),
        "availableFromMe": row.produced - row.selfConsumed,
        "availableFromCommunity": row.producedByCommunity - row.consumedFromCommunity
      }));

      setPowers(transformedResult);
      setDataAccessStatus(DataAccessStatuses.FRESH_DATA);
    },
    (error) => {
      errors.add(error);
      if (Object.keys(powers).length !== 0) {
        setDataAccessStatus(DataAccessStatuses.ERROR_STALE_DATA);
      } else {
        setDataAccessStatus(DataAccessStatuses.ERROR_NO_DATA);
      }
    },
    dataAccessStatus === DataAccessStatuses.LOADING,
    60 * 1000, [date]);

  return (
    <div className="h-full flex flex-col text-center align-middle relative">
      {(dataAccessStatus === DataAccessStatuses.LOADING) ?
        <div className="text-amber-900">{t('loading')}...</div>
        :
        (dataAccessStatus !== DataAccessStatuses.ERROR_NO_DATA) && <TimescaleLine data={powers} x="time" y={fields} date={date} onDateChange={onDateChange} t={t} unit="W" />
      }
      <div className={(dataAccessStatus === DataAccessStatuses.ERROR_NO_DATA ? "" : "hidden ") + "text-red-700"}>{t('MemberDashboard:unable_to_fetch')}</div>
      {(dataAccessStatus === DataAccessStatuses.ERROR_STALE_DATA) &&
        <div className="z-50 text-center absolute text-xsm top-9 mx-auto w-full text-red-700">
          <div className="mx-auto w-max bg-white bg-opacity-80">
            <span>{t('MemberDashboard:unable_to_update')}</span>
          </div>
        </div>}
    </div>
  );
}


