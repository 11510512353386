import React from 'react';

export default function ChartsTooltip({ y, payload, label, labelFormatter, unit }) {
  return (
    <div className='rounded text-xxsm md:text-xsm p-1 md:p-1 bg-white bg-opacity-60 text-amber-900 border border-amber-900'>
      <div className='text-center -my-1'>{labelFormatter(label)}</div>
      {Object.keys(y).map(category => (
        <React.Fragment key={category}>
          <div>{y[category]['label']}</div>
          {Object.values(y[category].fields).map((field, index) => (
            <div key={index} className='ml-1 md:ml-2 -my-1 grid grid-cols-3'>
              <div className='flex items-center col-span-2'>
                <div className={"w-2 h-2 bg-[" + field.color + "] block"}></div>
                <div className='ml-1'>{field.label}</div>
              </div>
              <div className='ml-1'>{payload.filter((item) => { return item.dataKey === field.key; }).map((item) => { return item.value; })} {unit}</div>

            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

