import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import * as d3 from 'd3';
import { XAxis, YAxis, ResponsiveContainer, Legend, CartesianGrid, Tooltip, ReferenceArea, BarChart, Bar } from 'recharts';
import { getEndOfMonth, getEndOfYear, getStartOfMonth, getStartOfYear } from '../../utils/dateUtils';
import useIsMobile from "../../hooks/useIsMobile";
import { getTailwindColor } from '../../utils/colorUtils';
import ChartsLegend from './ChartsLegend';
import ChartsTooltip from "./ChartsTooltip";
import { createUseGesture, pinchAction, dragAction } from '@use-gesture/react';
import { getNiceTickValues } from "recharts-scale";

const DEFAULT_REF_AREA = { x1: "", x2: "" };
const amber900Color = getTailwindColor('text-amber-900');
const DEFAULT_WIDTH = 400;
const useGesture = createUseGesture([pinchAction, dragAction]);

//TODO: put in common the format functions (in a util ?)
function formatDateUrl(timestamp, granularity) {
  const date = new Date(timestamp);
  switch (granularity) {
    case 'year':
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    case 'month':
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    default:
      return null;
  }
}

function getDomainOffset(granularity) {
  switch (granularity) {
    case "year":
      return 15 * 24 * 1000 * 60 * 60;
    case "month":
      return 12 * 1000 * 60 * 60;
    default:
      return 0;
  }
}

export default function TimescaleBarChart({ data, x, y, date, granularity, destinationBasePath, onDateChange, t, unit }) {
  const [debugInfo, setDebugInfo] = useState('');

  const { startOfDomain, endOfDomain, ticksInterval, tickFormatter, tooltipLabelFormatter, domainOffset, maxZoom } = useMemo(() => getParams(date, granularity), [date, granularity]);
  const defaultZoomArea = useMemo(() => {
    return {
      x1: startOfDomain.unix() * 1000,
      x2: endOfDomain.unix() * 1000
    };
  }, [startOfDomain, endOfDomain]);

  const [activeIndex, setActiveIndex] = useState(-1);
  const [chartWidth, setChartWidth] = useState(0);
  const navigate = useNavigate();
  const [zoomArea, setZoomArea] = useState(defaultZoomArea);
  const [refArea, setRefArea] = useState(DEFAULT_REF_AREA);
  const [isZooming, setIsZooming] = useState(false);
  const [isZoomingByGesture, setIsZoomingByGesture] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [yTicks, setYTicks] = useState([]);
  const [maxYTick, setMaxYTick] = useState(0);


  const timeDomain = useMemo(() => { return d3.scaleTime().domain([startOfDomain, endOfDomain]); }, [startOfDomain, endOfDomain]);
  const defaultTicks = useMemo(() => { return timeDomain.ticks(ticksInterval); }, [timeDomain]);
  const [ticks, setTicks] = useState(defaultTicks);


  const isMobile = useIsMobile();
  const transformedData = useMemo(() => transform(data, x, y), [data, x, y]);

  const getAxisYDomain = useCallback((from, to) => {
    return transformedData
      .filter(d => d[x] >= from && d[x] <= to)
      .reduce((top, d) => {
        let sumD = Object.keys(d)
          .filter(key => key !== 'startTime')
          .reduce((acc, key) => acc + d[key], 0);
        return top < sumD ? sumD : top;
      }, 0);
  }, [transformedData, x, y]);

  const updateYTicks = useCallback((x1, x2) => {
    let newYTicks = getNiceTickValues([0, getAxisYDomain(x1, x2)], 8);
    setYTicks(newYTicks);
    setMaxYTick(newYTicks[newYTicks.length - 1]);
  }, [getAxisYDomain]);

  const handleZoomOut = useCallback((event) => {
    setIsZoomed(false);
    setZoomArea(defaultZoomArea);
    setRefArea(DEFAULT_REF_AREA);
    setTicks(defaultTicks);
    updateYTicks(defaultZoomArea.x1, defaultZoomArea.x2);
  }, [defaultZoomArea, updateYTicks, setIsZoomed, setZoomArea, defaultTicks, setTicks]);

  useEffect(() => {
    const handler = (e) => e.preventDefault();
    document.addEventListener('gesturestart', handler);
    document.addEventListener('gesturechange', handler);
    document.addEventListener('gestureend', handler);
    return () => {
      document.removeEventListener('gesturestart', handler);
      document.removeEventListener('gesturechange', handler);
      document.removeEventListener('gestureend', handler);
    };
  }, []);

  const ref = useRef();
  const chartRef = useRef(null);

  useGesture(
    {
      onDrag: ({ pinching, cancel, movement: [x], memo, first, swipe: [swipeX], active }) => {
        if (pinching) return cancel();

        setIsZoomingByGesture(active);
        chartRef.current.setState({ isTooltipActive: false });

        if (!isZoomed && swipeX != 0 && onDateChange) {
          const newDate = (swipeX < 0) ? moment(date).add(1, granularity).toDate() : moment(date).subtract(1, granularity).toDate();
          onDateChange(newDate);
          return cancel();
        }

        if (!isZoomed) return memo;
        const rect = ref.current.getBoundingClientRect();
        if (first) {
          memo = [zoomArea.x1, zoomArea.x2];
        }
        const [zoomAreaX1, zoomAreaX2] = memo;
        const ratio = x / (rect.width - 50);
        const xTime = ratio * (zoomAreaX2 - zoomAreaX1);
        let newRefArea = { x1: zoomAreaX1 - xTime, x2: zoomAreaX2 - xTime };
        if (newRefArea.x1 < defaultZoomArea.x1) {
          newRefArea = {
            x1: defaultZoomArea.x1,
            x2: defaultZoomArea.x1 + (zoomAreaX2 - zoomAreaX1)
          };
        }
        if (newRefArea.x2 > defaultZoomArea.x2) {
          newRefArea = {
            x1: defaultZoomArea.x2 - (zoomAreaX2 - zoomAreaX1),
            x2: defaultZoomArea.x2
          };
        }
        setRefArea(newRefArea);
        return memo;
      },
      onPinch: ({ origin: [ox], movement: [scale], memo, first, active }) => {
        const rect = ref.current.getBoundingClientRect();
        if (first) {
          memo = [zoomArea.x1, zoomArea.x2, ox];
        }
        setIsZoomingByGesture(active);
        chartRef.current.setState({ isTooltipActive: false });
        const [zoomAreaX1, zoomAreaX2, initialOx] = memo;
        const ratio = (initialOx - rect.x - 40) / (rect.width - 50);
        const oxTime = zoomAreaX1 + ratio * (zoomAreaX2 - zoomAreaX1);
        let newRefArea = { x1: oxTime + (zoomAreaX1 - oxTime) / scale, x2: oxTime + (zoomAreaX2 - oxTime) / scale };
        const MIN_DISTANCE = 3 * 60 * 1000;
        if (Math.abs(newRefArea.x2 - newRefArea.x1) < 3 * 60 * 1000) {
          const midPoint = (newRefArea.x1 + newRefArea.x2) / 2;
          newRefArea = { x1: midPoint - MIN_DISTANCE / 2, x2: midPoint + MIN_DISTANCE / 2 };
        }
        if (newRefArea.x1 < defaultZoomArea.x1 || newRefArea.x2 > defaultZoomArea.x2) {
          newRefArea = defaultZoomArea;
        }
        setRefArea(newRefArea);
        return memo;
      }
    },
    {
      target: ref,
      pinch: { scaleBounds: { min: 1, max: maxZoom } },
      drag: {
        filterTaps: true,
        threshold: 10
      }
    }
  );

  const showZoomBox = isZooming;

  function handleMouseDown(e) {
    if (isMobile) return;
    setIsZooming(true);
    const newX1 = moment(e.activeLabel).subtract(domainOffset).unix() * 1000;
    setRefArea({ x1: newX1, x2: "" });
  }

  function handleMouseMove(e) {
    if (isZooming) {
      const newX2 = moment(e.activeLabel).add(domainOffset).unix() * 1000;
      setRefArea((prev) => ({ ...prev, x2: newX2 }));
    }
    if (e.isTooltipActive) {
      setActiveIndex(e.activeTooltipIndex);
    } else {
      setActiveIndex(-1);
    }
  }

  function handleMouseUp(e) {
    setIsZooming(false);
  }

  useEffect(() => {
    if (isZooming) return;
    let { x1, x2 } = refArea;
    if (x1 === defaultZoomArea.x1 && x2 === defaultZoomArea.x2) {
      handleZoomOut();
      return;
    }
    if (x1 === null || x2 === null || x1 === x2 || x2 === "") {
      return;
    }
    if (x1 > x2) [x1, x2] = [x2, x1];
    setZoomArea({ x1: x1, x2: x2 });
    let newTimeDomain = d3.scaleTime().domain([x1, x2]);
    setIsZoomed(true);
    setTicks(newTimeDomain.ticks(ticksInterval));
  }, [isZooming, refArea, defaultZoomArea.x1, defaultZoomArea.x2, handleZoomOut, ticksInterval]);

  useEffect(() => {
    updateYTicks(zoomArea.x1, zoomArea.x2);
  }, [transformedData, updateYTicks, zoomArea.x1, zoomArea.x2]);

  const handleBarClick = (data) => {
    let url = `${destinationBasePath}/${formatDateUrl(data.startTime, granularity)}`;
    navigate(url);
  };

  function getReferenceAreaProps(index) {
    if (index === -1) {
      return {};
    }
    let x1;
    if (index <= transformedData.length - 1) {
      x1 = transformedData[index][x] - domainOffset;
    }
    else {
      x1 = null;
    }
    let x2;
    if (index < transformedData.length - 1) {
      x2 = transformedData[index + 1][x] - domainOffset;
    } else {
      x2 = null;
    }
    const d = data[index];
    return { x1, x2, d };
  }

  const { x1, x2, d } = getReferenceAreaProps(activeIndex);


  useEffect(() => {
    setChartWidth(ref.current ? ref.current.getBoundingClientRect().width : DEFAULT_WIDTH);
  });

  return (
    <div ref={ref} className='relative flex flex-col w-full h-full z-10 select-none font-sans relative touch-none items-center'>
      {(isZoomed && !isMobile) &&
        <button
          type="button"
          className='top-0 absolute z-50 right-auto font-normal justify-center rounded-md border px-3 py-1 text-sm text-amber-900 shadow-sm border-amber-900 bg-white'
          onClick={handleZoomOut}
        >
          {t('MemberDashboard:reset_zoom')}
        </button>}
      <div className='absolute z-50 left-0 bg-white text-black text-xsm text-left'>{debugInfo}</div>
      <ResponsiveContainer width={'100%'} height={'100%'} >
        <BarChart
          ref={chartRef}
          width={730}
          height={250}
          data={transformedData}
          margin={{ top: 5, right: 5, bottom: 10, left: -25 }}
          onMouseMove={e => handleMouseMove(e)}
          onMouseUp={e => handleMouseUp(e)}
          onMouseDown={e => handleMouseDown(e)}
        >
          <CartesianGrid strokeDasharray='3 3' opacity={0.1} stroke={amber900Color} />
          <XAxis
            dataKey={x}
            allowDataOverflow={true}
            tickFormatter={(unix) => moment(unix).format(tickFormatter)}
            domain={[zoomArea.x1, zoomArea.x2]}
            scale='time'
            type='number'
            ticks={ticks}
            tick={{ fontSize: isMobile ? 8 : 10, fill: amber900Color }}
            tickLine={{ stroke: amber900Color }}
            axisLine={{ stroke: amber900Color, strokeWidth: 1, opacity: 0.2 }}
          />

          <ReferenceArea
            x1={refArea?.x1}
            x2={refArea?.x2}
            fill="#ddd"
            opacity={showZoomBox ? '100%' : '0%'}
          />

          <ReferenceArea
            x1={x1}
            x2={x2}
            fill="#fff"
            ifOverflow="extendDomain"
            onClick={() => handleBarClick(d)}
            style={{ cursor: 'pointer', opacity: '50%' }}

          />
          <YAxis
            label={{ fontSize: isMobile ? 8 : 10, value: unit, angle: -90, position: 'insideTopLeft', offset: 45, style: { fill: amber900Color } }}
            tick={{ fontSize: isMobile ? 8 : 10, fill: amber900Color }}
            tickLine={{ stroke: amber900Color }}
            axisLine={{ stroke: amber900Color, strokeWidth: 1, opacity: 0.2 }}
            allowDataOverflow={true}
            domain={[0, maxYTick ? maxYTick : 5000]}
            ticks={yTicks}
          />

          {(!showZoomBox && !isZoomingByGesture) &&
            <Tooltip
              content={<ChartsTooltip y={y} unit={unit} />}
              labelFormatter={(unixTime) => moment(unixTime).format(tooltipLabelFormatter)}
              position={isMobile ? { x: 50, y: 10 } : { y: 0 }}
            />}

          <Legend content={<ChartsLegend y={y} />} />
          {Object.keys(y).map(category => Object.keys(y[category]['fields']).map((field, index) => <Bar
            key={y[category]['fields'][field]['key']}
            dataKey={y[category]['fields'][field]['key']}
            fill={y[category]['fields'][field]['color']}
            stackId={category}
            onMouseUp={e => { if (activeIndex >= 0) handleBarClick(e); }}
            style={{ cursor: 'pointer' }}
            barSize={chartWidth / (2 * ticks.length)}
            isAnimationActive={false}
          />))}
        </BarChart>
      </ResponsiveContainer>
    </div >
  );
}

function transform(data, x, y) {
  if (data.length === 0) return [];
  let updatedData = data.map((item) => {
    let result = { [x]: moment(item[x]).unix() * 1000 };
    Object.keys(y).forEach(category => {
      Object.keys(y[category]['fields']).forEach(field => {
        result[y[category]['fields'][field]['key']] = item[field] / 1000;
      });
    });
    return result;
  });
  return updatedData;
}

function getParams(date, granularity) {
  const domainOffset = getDomainOffset(granularity);
  switch (granularity) {
    case "year":
      return {
        startOfDomain: getStartOfYear(date).subtract(domainOffset),
        endOfDomain: getEndOfYear(date).subtract(domainOffset),
        ticksInterval: d3.timeMonth,
        tickFormatter: 'MM',
        tooltipLabelFormatter: 'MMMM YYYY',
        domainOffset: domainOffset,
        maxZoom: 12
      };
    default:
      return {
        startOfDomain: getStartOfMonth(date).subtract(domainOffset),
        endOfDomain: getEndOfMonth(date).subtract(domainOffset),
        ticksInterval: d3.timeDay,
        tickFormatter: 'DD',
        tooltipLabelFormatter: 'LL',
        domainOffset: domainOffset,
        maxZoom: 28
      };
  };
}


